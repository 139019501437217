export default {
  getCurrentStep: state => state.currentStep,
  getFormValues: state => state.formValues,
  getFormErrors: state => state.formErrors,
  isLoading: state => state.isLoading,
  isComplete: state => state.isComplete,
  getErrorMessage: state => state.errorMessage,
  getHowDiscovered: state => state.howDiscovered,
  getToken: state => state.token,
  isEmailTaken: state => state.emailIsTaken,
}
