export default {

    nextStep(state) {
        state.currentStep++;

    },
    previousStep(state) {
        state.currentStep--;
    },

    isLoading(state, isLoading) {
        state.isLoading = isLoading;
    },

    complete(state) {
        state.isComplete = true;
    },

    saveFormValues(state, formFields) {
        state.formValues = {...state.formValues, ...formFields.formFields}
    },

    setFormErrors(state, formErrors) {
        console.log('setFormErrors', formErrors);
        state.formErrors = [...formErrors.formErrors]
    },

    setErrorMessage(state, errorMessage) {
        state.errorMessage = errorMessage;
    },

    setHowDiscovered(state, howDiscovered) {
        state.howDiscovered = howDiscovered;
    },

    clearErrorMessage(state) {
        state.errorMessage = '';
    },
    setToken(state, token) {
        state.token = token;
    },
    clearToken(state) {
        state.token = null;
    },
    emailIsTaken(state) {
        state.emailIsTaken = true;
    }
}

