const state = {
    isLoading: false,
    isComplete: false,
    token: null,
    errorMessage: '',
    currentStep: 1,
    emailIsTaken: false,
    howDiscovered: null,
    formValues: {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        gender: '',
        date_of_birth: '',
        zip_code: '',
        house_number: '',
        house_number_addition: '',
        street_name: '',
        city: '',
        phone_number_parent: '',
        how_discovered: '',
        password: '',
        analytics_id: null
    },
    formErrors: ['first_name']
}

export default state
