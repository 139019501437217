<script>
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import Step4 from './Step4.vue';
import Steps from './Steps.vue';
import DownloadApp from './DownloadApp.vue';
import {mapGetters} from "vuex";
import Loader from "../partials/Loader.vue";
import store from "./store";

export default {
  name: 'app',
  props: ['howDiscovered'],
  components: {
    'step1': Step1,
    'step2': Step2,
    'step3': Step3,
    'step4': Step4,
    'downloadApp': DownloadApp,
    'loader': Loader,
    'steps': Steps,
  },

  computed: mapGetters({
    currentStep: "getCurrentStep",
    formValues: 'getFormValues',
    formErrors: 'getFormErrors',
    isLoading: 'isLoading',
    isComplete: 'isComplete',
    errorMessage: 'getErrorMessage',
    emailIsTaken: 'isEmailTaken',
    howDiscovered: 'getHowDiscovered'
  }),
  mounted() {
    const howDiscovered = this.howDiscovered !== '' ? this.howDiscovered:  null;
    if (howDiscovered !== null) {
      console.log('setHowDiscovered', howDiscovered);
      store.commit('setHowDiscovered', howDiscovered);
      store.commit('saveFormValues', { formFields: {how_discovered: howDiscovered} });
    }
  }
}
</script>

<template>


  <div :class="{ loading: isLoading }" class="position-relative delivery-form-wrapper">
    <loader :isLoading="isLoading"></loader>

    <template v-if="this.emailIsTaken">
      <downloadApp class="email-is-taken"></downloadApp>
    </template>
    <template v-else>
      <div v-if="errorMessage.length" class="alert alert-danger" role="alert">{{ errorMessage }}</div>
      <step1 v-if="+this.currentStep === 1" :formValues="formValues" :formErrors="formErrors" />
      <step2 v-if="+this.currentStep === 2" :formValues="formValues"/>
      <step3 v-if="+this.currentStep === 3" :formValues="formValues" :how-discovered="howDiscovered" />
      <step4 v-if="+this.currentStep === 4" :formValues="formValues"/>
      <steps :currentStep="currentStep"/>
    </template>
  </div>


</template>
