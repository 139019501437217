<script>
export default {
  name: 'loader',
  props: ['isLoading'],
}
</script>

<template>
  <div class="loader-overlay" v-if="isLoading">
    <span class="loader"></span>
  </div>
</template>
