<script>
import store from './store';
import DeliveryService from "../../services/DeliveryService.js";
import {validateEmail, validatePhoneNumber} from "../../helpers/validation_helper";
import {getAnalyticsClientId, setCurrentAnalyticsStep} from "../../helpers/tracking_helper";

export default {
    name: 'step-1',
    props: ['formValues', 'formErrors'],

    data() {
        return {
            formFields: {
                first_name: this.formValues.first_name,
                middle_name: this.formValues.middle_name,
                last_name: this.formValues.last_name,
                email: this.formValues.email,
                phone_number: this.formValues.phone_number,
                analytics_id: this.formValues.analytics_id
            },
            emailTakenError: false,
            emailNotValid: false,
            errors: this.formErrors,
            formErrorsArray: []
        }
    },
    methods: {
        numbersOnly: function (event) {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
            const keyPressed = event.key;

            if (!keysAllowed.includes(keyPressed)) {
                event.preventDefault();
            }
        },
        onSubmit() {
            this.emailTakenError = false;
            this.emailNotValid = false;
            const errors = this.getFormErrors();
            this.formErrorsArray = errors;

            if (this.formErrorsArray.length > 0) {
                return;
            }


            store.commit('isLoading', true);
            DeliveryService.emailTakenCheck(this.formFields.email)
                .then((res) => {
                    setCurrentAnalyticsStep('step_one');

                    getAnalyticsClientId().then((id) => {
                      this.formFields.analytics_id = id;
                      this.goToNextStep();
                    }).catch((err) => {
                      this.goToNextStep();
                    });
                })
                .catch((e) => {

                    store.commit('isLoading', false);
                    if (e.status === 409) {
                        this.emailTakenError = true;
                        store.commit('emailIsTaken');
                    } else if (e.status === 422) {
                        this.emailNotValid = true;
                    } else {
                        store.commit('setErrorMessage', 'Er ging wat mis bij het verwerken van de data. Probeer het opnieuw.');
                    }
                });
        },
        goToNextStep() {
          store.commit('isLoading', false);
          store.commit('setErrorMessage', '');
          store.commit('saveFormValues', {formFields: this.formFields});
          store.dispatch('saveDeliveryRequest');
          store.commit('nextStep');
        },
        getFormErrors() {
            const errors = [];
            if (this.formFields.first_name == '') {
                errors.push('first_name');
            }
            if (this.formFields.last_name == '') {
                errors.push('last_name');
            }
            if (!validateEmail(this.formFields.email)) {
                errors.push('email');
            }
            if (!validatePhoneNumber(this.formFields.phone_number)) {
                errors.push('phone_number');
            }
            return errors;
        }
    },

    computed: {
        canSubmit() {
            if (this.formFields.first_name === '' || this.formFields.last_name === '' || this.formFields.email === '' || this.formFields.phone_number === '') {
                return false;
            }

            return true;
        },
    }
}
</script>

<template>
    <form @submit.prevent="onSubmit" class="step-1" id="bezorgformulier_step_1" name="Bezorgformulier stap 1">
        <div class="row">
            <div class="col-12">
                <h3 class="df-title">Meld je <span class="m-title-highlight">hier</span> aan</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mb-3">
                <input class="form-control" :class="{'is-invalid': formErrorsArray.indexOf('first_name') !== -1}"
                       type="text" name="first_name" placeholder="Voornaam" v-model="formFields.first_name" />
              <small class="text-danger" v-if="formErrorsArray.indexOf('first_name') !== -1">Voornaam is een verplicht veld!</small>
            </div>
            <div class="col-md-4 mb-3">
                <input class="form-control" type="text" v-model="formFields.middle_name" name="middle_name" placeholder="Tussenvoegsel" />
            </div>
            <div class="col-md-8 mb-3">
                <input class="form-control" :class="{'is-invalid': formErrorsArray.indexOf('last_name') !== -1}"
                       type="text" v-model="formFields.last_name" name="last_name" placeholder="Achternaam" />
              <small class="text-danger" v-if="formErrorsArray.indexOf('last_name') !== -1">Achternaam is een verplicht veld!</small>
            </div>
            <div class="col-md-12 mb-3">
                <input class="form-control" :class="{'is-invalid': formErrorsArray.indexOf('email') !== -1}"
                       type="email" v-model="formFields.email" name="email" placeholder="E-mailadres" />
              <small class="text-danger" v-if="formErrorsArray.indexOf('email') !== -1">E-mailadres is een verplicht veld!</small>
              <small class="text-danger" v-if="emailTakenError">Dit e-mailadres is al bekend bij ons</small>
              <small class="text-danger" v-if="emailNotValid">Vul een geldig email adres in</small>

            </div>
            <div class="col-md-12 mb-4">
                <input class="form-control" :class="{'is-invalid': formErrorsArray.indexOf('phone_number') !== -1}"
                       type="tel" @keydown="numbersOnly" min="0" maxlength="10" v-model="formFields.phone_number"
                       name="phone_number" placeholder="Telefoonnummer (0612345678)" />
              <small class="text-danger" v-if="formErrorsArray.indexOf('phone_number') !== -1">Voer een geldig telefoonnummer in!</small>
            </div>
            <div class="col-md-12 mb-4">
                <button type="submit" class="m-button btn button deliver-btn">Volgende <i class="fa fa-chevron-right"></i></button>
            </div>
        </div>
    </form>
</template>

