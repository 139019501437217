async function sha256(message) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export function setUserData(formData) {

    return new Promise(resolve => {
        if (!Cookiebot || !Cookiebot.consent.marketing) {
            resolve();
            return;
        }

        const email = sha256(formData.email);
        const phone_number = sha256(formData.phone_number);
        const first_name = sha256(formData.first_name);
        const last_name = sha256(formData.last_name);

        Promise.all([email, phone_number, first_name, last_name])
            .then((values) => {
                const userData = {
                    sha256_email_address: values[0],
                    sha256_phone_number: values[1],
                    address: {
                        "sha256_first_name": values[2],
                        "sha256_last_name": values[3],
                        city: formData.city,
                        postal_code: formData.zip_code,
                    }
                }
                gtag('set', 'user_data', userData);
                resolve();
            })
            .catch((error) => {
                resolve();
            });
    });
}

export function getAnalyticsClientId() {
    return new Promise(resolve => {
        if (!Cookiebot || !Cookiebot.consent.statistics) {
            resolve(null);
            return;
        }

        gtag('get', document.getElementById('gtmMeasurementId').value, 'client_id', resolve);
    })
}


export function setCurrentAnalyticsStep(step) {
    gtag('event', 'form_interaction', {
        'form_id': 'df1',
        'form_name': 'Aanmeldingsformulier bezorgers',
        'progress': step
    });
}
