<script>
import config from '../../config'
import {setCurrentAnalyticsStep} from "../../helpers/tracking_helper";

export default {
    name: 'DownloadApp',
    data() {
        return {
            imagePath: config.IMAGE_PATH
        }
    },
    mounted() {
        setCurrentAnalyticsStep('step_download_app');
    },
}
</script>

<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h3 class="df-title">Meld je <span class="m-title-highlight">hier</span> aan</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mb-4">
                <div class="m-text">
                    <p>Hey, je bent al bekend bij Spotta. Het kan zijn dat we (nog) geen wijk voor jou beschikbaar
                        hebben.
                        Download onze nieuwe Mijn Spotta bezorgersapp. Hierin kan je precies zien wat jouw
                        bezorgers-status
                        is en welke wijken er beschikbaar zijn.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <a class="mr-3"
                   href='https://play.google.com/store/apps/details?id=nl.spotta.mijnspotta&hl=nl&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img style="width: 176px;" alt='Ontdek het op de app store'
                         :src="'/resources/social-icons/download-app-store.svg'"/>
                </a>
                <a href='https://play.google.com/store/apps/details?id=nl.spotta.mijnspotta&hl=nl&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img style="width: 200px;" alt='Ontdek het op Google Play'
                         :src="'/resources/social-icons/google-play-badge.png'"/>
                </a>
            </div>
        </div>
    </div>
</template>

