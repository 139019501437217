export const validateEmail = (email) => {
  if (!email || email == '') {
    return false;
  }
  let regex = new RegExp("[a-zA-Z0-9.-]+@[a-zA-Z0-9]{1,80}(?:\\.[a-zA-Z0-9]{1,5}){0,10}\\.[a-zA-Z0-9]{2,10}");

  if (!regex.test(email)) {
    return false;
  }

  return true;
}

export const validatePhoneNumber = (phoneNumber) => {
  if (!phoneNumber || phoneNumber == '' || phoneNumber.length !== 10) {
    return false;
  }

  return true;
}
