<script>

export default {
  name: 'steps',
  props: ['currentStep'],

}
</script>

<template>

  <div class="stepper-wrapper">
    <div class="stepper-item " :class="{ completed: currentStep > 1, active: currentStep===1 }">
      <div class="step-name">Aanmelden</div>
      <div class="step-counter">&nbsp;</div>
    </div>
    <div class="stepper-item " :class="{ completed: currentStep > 2 , active: currentStep===2}">
      <div class="step-name">Gegevens</div>
      <div class="step-counter">&nbsp;</div>
    </div>
    <div class="stepper-item " :class="{ completed: currentStep > 3, active: currentStep===3 }">
      <div class="step-name">Aanvullende info</div>
      <div class="step-counter">&nbsp;</div>
    </div>
    <div class="stepper-item"  :class="{ completed: currentStep > 4, active: currentStep===4 }">
      <div class="step-name">Mijn Spotta</div>
      <div class="step-counter">&nbsp;</div>
    </div>
  </div>
</template>

