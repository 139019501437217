import {createStore} from 'vuex'
import state from './State'
import mutations from './Mutations'
import getters from './Getters'
import actions from './Actions'

// Create a new store instance.
export default createStore({
  state,
  getters,
  actions,
  mutations
})


