<script>
import store from './store';
import DeliveryService from "../../services/DeliveryService.js";
import {debounce} from "debounce";
import {setCurrentAnalyticsStep} from "../../helpers/tracking_helper";

export default {
  name: 'step-2',

  props: ['formValues'],

  data() {
    return {
      formFields: {
        gender: this.formValues.gender,
        date_of_birth: this.formValues.date_of_birth,
        zip_code: this.formValues.zip_code,
        street_name: this.formValues.street_name,
        house_number: this.formValues.house_number,
        house_number_addition: this.formValues.house_number_addition,
        city: this.formValues.city,
        debounceAddressSearch: null,
      },
      dobFocused: false,
      editAddressFields: false,
      houseNumberAdditions: [],
      showAdditionField: false,
      showAdditionManualField: false,
      additionValue: '',
      additionManualValue: '',
      addressNotFound: false,
      isLoadingAddress: false,
      formErrorsArray: []
    }
  },
  mounted() {
    this.additionManualValue = this.formFields.house_number_addition;
    if (this.additionManualValue !== '') {
      this.showAdditionManualField = true;
    }

    this.debounceAddressSearch = debounce(this.addressSearch, 1500);
  },
  methods: {
    onSubmit() {
      if (this.showAdditionField) {
        this.formFields.house_number_addition = this.additionValue;
      }

      if (this.showAdditionManualField) {
        this.formFields.house_number_addition = this.additionManualValue;
      }

      this.formErrorsArray = this.getFormErrors();

      if (this.formErrorsArray.length > 0) {
        return;
      }

      setCurrentAnalyticsStep('step_two');
      store.commit('saveFormValues', {formFields: this.formFields})
      store.dispatch('saveDeliveryRequest');
      store.commit('nextStep');
    },
    getFormErrors() {
      const errors = [];
      if (this.formFields.gender == '') {
        errors.push('gender');
      }
      if (this.formFields.date_of_birth == '') {
        errors.push('date_of_birth');
      }
      if (this.formFields.zip_code == '') {
        errors.push('zip_code');
      }
      if (this.formFields.house_number == '') {
        errors.push('house_number');
      }
      if (this.formFields.street_name == '') {
        errors.push('street_name');
      }
      if (this.formFields.city == '') {
        errors.push('city');
      }
      return errors;
    },
    previousStep() {
      store.commit('previousStep');
    },
    completeAddress() {
      if (this.formFields.zip_code === '' || this.formFields.house_number === '') {
        return;
      }

      this.clearAddress();

      this.debounceAddressSearch();
    },
    clearAddress() {
      this.formFields.street_name = '';
      this.formFields.city = '';
      this.formFields.house_number_addition = '';
    },
    addressSearch() {
      this.houseNumberAdditions = [];
      this.showAdditionField = false;
      this.showAdditionManualField = false;
      this.additionValue = '';
      this.additionManualValue = '';
      this.isLoadingAddress = true;
      this.addressNotFound = false;
      DeliveryService.findAddress(this.formFields.zip_code, this.formFields.house_number)
          .then((res) => {
            this.isLoadingAddress = false;
            if (res.body.straatnaam !== '' && res.body.plaatsnaam !== '') {
              this.formFields.street_name = res.body.straatnaam;
              this.formFields.city = res.body.plaatsnaam;

              if (res.body.appendices && res.body.appendices.length) {
                this.houseNumberAdditions = res.body.appendices;
                this.showAdditionField = true;
                this.showAdditionManualField = false;

              }
            } else {
              this.clearAddress();
              this.addressNotFound = true;
            }
          })
          .catch((e) => {
            this.isLoadingAddress = false;
            this.addressNotFound = true;
            this.clearAddress();
            // this.editAddressFields = true;
            // this.showAdditionManualField = true;
          });
    },
  },
  computed: {
    canSubmit() {
      if (this.formFields.gender === '' || this.formFields.date_of_birth === '' || this.formFields.zip_code === '' || this.formFields.house_number === '' || this.formFields.street_name === '' || this.formFields.city === '') {
        return false;
      }

      return true;
    },
    currentDate() {
      let date = new Date();
      let day = ("0" + date.getDate()).slice(-2);
      let month = ("0" + (date.getMonth() + 1)).slice(-2);
      let year = date.getFullYear();

      let formattedDate = year + '-' + month + '-' + day;
      return formattedDate;
    }
  }
}
</script>

<template>
  <form @submit.prevent="onSubmit" class="step-2" id="bezorgformulier_step_2" name="Bezorgformulier stap 2">
    <div class="row">
      <div class="col-12">
        <h3 class="df-title">Meld je <span class="m-title-highlight">hier</span> aan</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-3">
        <select name="gender" class="custom-select show-ro-select" :class="{'is-invalid': formErrorsArray.indexOf('gender') !== -1}" v-model="formFields.gender">
          <option value="">Geslacht</option>
          <option value="M">Man</option>
          <option value="V">Vrouw</option>
          <option value="O">Zeg ik liever niet</option>
        </select>
        <small class="text-danger" v-if="formErrorsArray.indexOf('gender') !== -1">Geslacht is een verplicht veld!</small>
      </div>
      <div class="col-md-8 mb-3 position-relative">
        <div class="dob-placeholder">Geboortedatum</div>
        <input type="date" :max="currentDate" v-model="formFields.date_of_birth" name="date_of_birth" placeholder="Geboortedatum" class="form-control dob-input" :class="{'is-invalid': formErrorsArray.indexOf('date_of_birth') !== -1, 'no-value': !formFields.date_of_birth }" />
        <small class="text-danger" v-if="formErrorsArray.indexOf('date_of_birth') !== -1">Geboortedatum is een verplicht veld!</small>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-3">
        <input class="form-control" type="text" v-model="formFields.zip_code" :class="{'is-invalid': formErrorsArray.indexOf('zip_code') !== -1}" v-on:keyup="completeAddress"
               name="zip_code" placeholder="Postcode" />
        <small class="text-danger" v-if="formErrorsArray.indexOf('zip_code') !== -1">Postcode is een verplicht veld!</small>
      </div>
      <div class="col-md-4 mb-3">
        <input class="form-control" type="number" v-model="formFields.house_number" :class="{'is-invalid': formErrorsArray.indexOf('house_number') !== -1}" name="house_number" placeholder="Huisnummer" min="0"
               v-on:keyup="completeAddress" />
        <small class="text-danger" v-if="formErrorsArray.indexOf('house_number') !== -1">Huisnummer is een verplicht veld!</small>
      </div>
      <div class="col-md-4 mb-3">
        <select class="custom-select prevent-read-only" v-model="additionValue" v-if="showAdditionField">
          <option value="">Toevoeging</option>
          <option :value="addition" v-for="addition in houseNumberAdditions">{{ addition }}</option>
        </select>

        <input class="form-control" v-model="additionManualValue" placeholder="Toevoeging"
               v-if="showAdditionManualField" aria-label="">
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-3">
        <input class="form-control" type="text" v-model="formFields.street_name" :class="{'is-invalid': formErrorsArray.indexOf('street_name') !== -1}" placeholder="Straatnaam"
               data-readonly
               :readonly="!editAddressFields" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mb-4">
        <input class="form-control" type="text" v-model="formFields.city" :class="{'is-invalid': formErrorsArray.indexOf('city') !== -1}" placeholder="Plaats" data-readonly
               :readonly="!editAddressFields"
               aria-label="default input example">
      </div>
    </div>
    <div class="row" v-if="addressNotFound">
      <div class="col-md-12 mb-4">
        <small class="text-danger">We hebben geen adres kunnen vinden voor de opgegeven postcode en huisnummer combinatie</small>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mb-4">
        <button type="button" class="m-button btn button default-btn" v-on:click="previousStep"><i class="fa fa-chevron-left"></i> Vorige</button>
        <button type="submit" class="m-button btn button deliver-btn float-right"
                :disabled="isLoadingAddress"><i
            class="fa fa-spinner fa-spin" v-if="isLoadingAddress"></i>&nbsp;Volgende <i class="fa fa-chevron-right"></i>
        </button>
      </div>
      <div class="col-md-12">
        <div class="m-text">
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
  </form>
</template>

