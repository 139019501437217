import DeliveryService from "../../../services/DeliveryService.js";
import config from '../../../config'
import {setUserData} from "../../../helpers/tracking_helper";

export default {
  completeSubscription({commit, getters}) {
    commit('isLoading', true);
    commit('clearErrorMessage');

    const data = getters.getFormValues;

    DeliveryService.subscribe(getters.getToken, data)
      .then((res) => {
        commit('complete');
        commit('clearToken');

        try {
          setUserData(data).then(() => {
            window.location.href = '/bezorger-worden/bedankt';
          })
        } catch (exception) {
          window.location.href = '/bezorger-worden/bedankt';
        }
      })
      .catch(() => {
        commit('isLoading', false);
        commit('setErrorMessage', 'Er ging iets mis bij het aanmelden. Probeer het opnieuw ...');
      })
  },
  saveDeliveryRequest({commit, getters}) {
    DeliveryService.saveRequest(getters.getToken, getters.getFormValues)
      .then((res) => {
        commit('setToken', res.body.token);
      })
      .catch((e) => {
        console.error(e);
      })
  }
}
