import {Http} from 'vue-resource'
import config from '../config'

class DeliveryService {
  findAddress(zipCode, houseNumber) {
    const $this = this;
    return Http.post('/api/delivery-form/find-address', {zip_code: zipCode, house_number: houseNumber}, {
      emulateJSON: true,
      before(request) {
        if ($this.previousRequest) {
          $this.previousRequest.abort();
        }

        $this.previousRequest = request;
      }
    })
  }

  getDiscoveryOptions() {
    const $this = this;
    return Http.get('/api/delivery-form/discovery-options', {
      emulateJSON: true,
      before(request) {
        if ($this.previousRequest) {
          $this.previousRequest.abort();
        }

        $this.previousRequest = request;
      }
    })
  }

  subscribe(token, data) {
    data.token = token
    const $this = this;
    return Http.post('/api/delivery-form/subscribe', data, {
      emulateJSON: true,
      before(request) {
        if ($this.previousRequest) {
          $this.previousRequest.abort();
        }

        $this.previousRequest = request;
      }
    })
  }

  emailTakenCheck(email) {
    const $this = this;
    return Http.post('/api/delivery-form/email-taken-check', {email}, {
      emulateJSON: true,
      before(request) {
        if ($this.previousRequest) {
          $this.previousRequest.abort();
        }

        $this.previousRequest = request;
      }
    })
  }

  saveRequest(token, data) {
    data.token = token
    return Http.post('/api/delivery-form/save-request', data, {
      emulateJSON: true,
    })
  }
}

const instance = new DeliveryService()

export default instance

