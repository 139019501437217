<script>
import store from './store';
import DeliveryService from '../../services/DeliveryService';
import {validatePhoneNumber} from "../../helpers/validation_helper";
import {setCurrentAnalyticsStep} from "../../helpers/tracking_helper";

export default {
  name: 'step-3',
  props: ['formValues', 'howDiscovered'],

  data() {
    return {
      formFields: {
        phone_number_parent: this.formValues.phone_number_parent,
        how_discovered: this.formValues.how_discovered,
      },
      discoveryOptions: [],
      formErrorsArray: []
    }
  },

  mounted() {
    store.commit('isLoading', true);
    DeliveryService.getDiscoveryOptions()
        .then((res) => {
          this.discoveryOptions = res.body;
          store.commit('isLoading', false);
        })
        .catch((e) => {
          store.commit('isLoading', false);
        })
  },

  methods: {
    numbersOnly: function (event) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
      const keyPressed = event.key;

      if (!keysAllowed.includes(keyPressed)) {
        event.preventDefault();
      }
    },
    onSubmit() {
      const errors = this.getFormErrors();
      this.formErrorsArray = errors;

      if (this.formErrorsArray.length > 0) {
        return;
      }

      setCurrentAnalyticsStep('step_three');
      store.commit('saveFormValues', {formFields: this.formFields});
      store.dispatch('saveDeliveryRequest');
      store.commit('nextStep');
    },

    getFormErrors() {
      const errors = [];

      if ((this.requiredParentPhoneNumber || this.formFields.phone_number_parent !== '') && !validatePhoneNumber(this.formFields.phone_number_parent)) {
        errors.push('phone_number_parent');
      }

      if (this.oldEnoughToContinue === false) {
        errors.push('oldEnoughToContinue');
      }

      if (this.formFields.how_discovered === '') {
        errors.push('how_discovered');
      }

      return errors;
    },

    previousStep() {
      store.commit('previousStep');
    }
  },

  computed: {
    canSubmit() {
      if (this.formFields.phone_number_parent === '' && (this.subscriberAge >= 13 && this.subscriberAge <= 16)) {
        return false;
      }

      if (this.oldEnoughToContinue === false) {
        return false;
      }

      if (this.formFields.how_discovered === '') {
        return false;
      }

      return true;
    },

    subscriberAge() {
      // const today = new Date();
      // const birthDate = new Date(this.formValues.date_of_birth);
      // const m = today.getMonth() - birthDate.getMonth();
      // let age = today.getFullYear() - birthDate.getFullYear();
      // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      //   age--;
      // }
      // return age;

      const oneDay = 24 * 60 * 60 * 1000;
      const diffDays = (new Date() - new Date(this.formValues.date_of_birth)) / oneDay;
      const age = diffDays / 365;
      return age;
    },
    requiredParentPhoneNumber() {
      return this.subscriberAge < 16;
    },
    showParentPhoneNumber() {
      return this.subscriberAge < 18;
    },
    phoneNumberDescription() {
      if (this.subscriberAge < 12.5) {
        return 'Je moet nog even geduld hebben voordat je bij ons mag bezorgen. Vanaf <strong class="m-text-highlight">12 jaar en 6 maanden</strong> kan je je aanmelden en vanaf <strong class="m-text-highlight">13 jaar</strong> mag je voor ons bezorgen.'
      } else if (this.subscriberAge < 13) {
        return 'Ben je jonger dan <strong class="m-text-highlight">13 jaar</strong>? Je kan je alvast aanmelden, maar je moet nog even geduld hebben voordat je bij ons mag bezorgen. Wanneer je 13 bent nemen we contact met je op om te vragen of je nog steeds wil bezorgen.'
      } else if (this.subscriberAge < 16) {
        return 'Je bent jonger dan <strong class="m-text-highlight">16 jaar</strong> oud. Dit betekent dat we het telefoonnummer van een ouder of verzorger nodig hebben. Wil je deze gegevens invullen?'
      }
    },
    oldEnoughToContinue() {
      return this.subscriberAge >= 12.5;
    }
  }
}
</script>

<template>
  <form @submit.prevent="onSubmit" class="step-3" id="bezorgformulier_step_3" name="Bezorgformulier stap 3">
    <div class="row">
      <div class="col-12">
        <h3 class="df-title">Meld je <span class="m-title-highlight">hier</span> aan</h3>
      </div>
    </div>
    <div class="row" v-if="requiredParentPhoneNumber">
      <div class="col-md-12 mb-4">
        <div class="m-text">
          <p v-html="phoneNumberDescription"></p>
        </div>
      </div>
    </div>
    <template v-if="oldEnoughToContinue">
      <div class="row" v-if="showParentPhoneNumber">
        <div class="col-md-12 mb-3">
          <input class="form-control" :class="{'is-invalid': formErrorsArray.indexOf('phone_number_parent') !== -1}" type="tel" @keydown="numbersOnly" min="0" maxlength="10" v-model="formFields.phone_number_parent" name="phone_number_parent" placeholder="Telefoonnummer ouder / verzorger (0612345678)" />
          <small class="text-danger" v-if="formErrorsArray.indexOf('phone_number_parent') !== -1">Voer een geldig telefoonnummer in!</small>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-4">
          <select name="how_discovered" class="custom-select show-ro-select" :disabled="!!howDiscovered" :class="{'is-invalid': formErrorsArray.indexOf('how_discovered') !== -1}" v-model="formFields.how_discovered">
            <option value="">Hoe heb je ons gevonden?</option>
            <option :value="discoveryOption.code" v-for="discoveryOption in discoveryOptions">
              {{ discoveryOption.omschrijving }}
            </option>
          </select>
          <small class="text-danger" v-if="formErrorsArray.indexOf('how_discovered') !== -1">Hoe heb je ons gevonden is een verplicht veld!</small>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-md-12 mb-4">
        <button type="button" class="m-button btn button default-btn" v-on:click="previousStep"><i class="fa fa-chevron-left"></i> Vorige</button>
        <button type="submit" class="m-button btn button deliver-btn float-right" v-if="oldEnoughToContinue">Volgende <i class="fa fa-chevron-right"></i></button>
      </div>
    </div>
    <div class="col-md-12">
      <div class="m-text">
        <p>&nbsp;</p>
      </div>
    </div>
  </form>
</template>

